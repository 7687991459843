import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'

import Header from '../../components/Header/Header'
import TopBanner from '../../components/Banners/TopBanner/TopBanner'
import Heading from '../../components/Blocks/heading/Heading'
import Footer from '../../components/Footer/Footer'
import Blocks from '../../components/Blocks/Blocks'
import ImageLeader from '../../components/ImageLeader/ImageLeader'
import SubLeader from '../../components/SubLeader/SubLeader'
import WeekendEscapesLauncher from '../../components/organisms/weekendEscapesLauncher/WeekendEscapesLauncher'
import { Link } from 'gatsby'
import * as BTPLStyles from '../../components/layoutComponents/bptl/BPTL.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import Hr from '../../components/Hr/Hr'
import PageMeta from '../../components/PageMeta/PageMeta'
import { type IWeekendEscapesData } from '../../graphql/queries/WeekendEscapesQuery'
import { POPULAR_SEARCHES, COUNTIES } from '../../lib/data/WeekendEscapes'
// import { LbbCategory } from 'types'
import CTARow from '../../components/CTA/CTARow'
import Social from '../../components/Social/Social'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'
import MPUBanner from '../../components/Banners/MPUBanner/MPUBanner'

export const query = graphql`
  query {
    wpgraphql {
      adStarCodes {
        id
        adslot
      }
      lBBCategory(id: "experiences", idType: SLUG) {
        description
        name
      }
      promoBoxesGroupSettings {
        promoBoxes
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
      }
      nationalPosts(category: "uk", first: 10) {
        id
        title
        uri
        linkType
        primaryCategory
        primaryCategoryURI
        spotlightDesktopImage
        spotlightMobileImage
        homepagePreviewImage
      }
      weekendFeatures(category: "south-west-weekend-escape") {
        id
        title
        uri
        linkType
        primaryCategory
        primaryCategoryURI
        spotlightDesktopImage
        spotlightMobileImage
        homepagePreviewImage
      }
      southCoast: weekendFeatures(category: "south-weekend-escape-feature") {
        id
        title
        uri
        linkType
        primaryCategory
        primaryCategoryURI
        spotlightDesktopImage
        spotlightMobileImage
        homepagePreviewImage
      }
      cotswolds: weekendFeatures(
        category: "weekend-escapes-cotswolds-special"
      ) {
        id
        title
        uri
        linkType
        primaryCategory
        primaryCategoryURI
        spotlightDesktopImage
        spotlightMobileImage
        homepagePreviewImage
      }
      southWest: weekendFeatures(category: "south-west-weekend-escape") {
        id
        title
        uri
        linkType
        primaryCategory
        primaryCategoryURI
        spotlightDesktopImage
        spotlightMobileImage
        homepagePreviewImage
      }
      peakDistrict: weekendFeatures(category: "peak-district-2024") {
        id
        title
        uri
        linkType
        primaryCategory
        primaryCategoryURI
        spotlightDesktopImage
        spotlightMobileImage
        homepagePreviewImage
      }
      eastAnglia: weekendFeatures(category: "weekend-escape-feature") {
        id
        title
        uri
        linkType
        primaryCategory
        primaryCategoryURI
        spotlightDesktopImage
        spotlightMobileImage
        homepagePreviewImage
      }
    }
  }
`

interface WeekendEscapesProps {
  data: IWeekendEscapesData
}

const WeekendEscapes = ({
  data: {
    wpgraphql: {
      adStarCodes,
      // weekendFeatures,
      eastAnglia,
      peakDistrict,
      southCoast,
      cotswolds,
      southWest,
      // lBBCategory,
      nationalPosts,
      generalSettings,
      promoBoxesGroupSettings
    }
  }
}: WeekendEscapesProps) => {
  const { siteId, title } = generalSettings
  const [experiences, setExperiences] = useState<any | null>(null)
  const recommended =
    nationalPosts && nationalPosts.length > 0 ? nationalPosts.slice(0, 5) : null
  const [searchTerm, setSearchTerm] = useState<string>()

  useEffect(() => {
    try {
      const json = JSON.parse(promoBoxesGroupSettings.promoBoxes)
      setExperiences(json)
    } catch (e) {
      setExperiences(null)
    }
  }, [promoBoxesGroupSettings])

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0)
      navigate(`/weekend-escapes/search/?search=${searchTerm}`)
  }, [searchTerm])

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Weekend Escapes | Muddy Stilettos',
          description:
            'Make your free time count – our carefully-curated guide to the most unique and chic hotels, pubs, bars, restaurants, cafés, cottages and spa breaks across England.',
          image:
            'https://muddystilettos.co.uk/images/weekend-escapes/Leader.jpg',
          url: 'https://muddystilettos.co.uk/weekend-escapes/'
        }}
      />
      <Header siteId={siteId} siteName={title} />
      <TopBanner ads={adStarCodes} showMPUs pageType={`weekendescapes`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      <ImageLeader short>
        <>
          <img
            src="/images/weekend-escapes/Leader.jpg"
            alt={`Weekend Escapes`}
          />
          <div className={BTPLStyles.Shader}></div>
          <div className={`photo-credit`}>Photo credit: Philip Edwards</div>
        </>
      </ImageLeader>
      <SubLeader colour={`LightBlue`}>
        <WeekendEscapesLauncher handleSearchChange={setSearchTerm} />
      </SubLeader>
      {experiences && experiences.box_1_visible === '1' && (
        <div className={BTPLStyles.HighlightBlock}>
          <div className={BTPLStyles.HighlightText}>
            <span>
              <h1>{experiences.box_1_title}</h1>
              <p>{experiences.box_1_description}</p>
              <span className={BTPLStyles.HighlightButtonRow}>
                <a
                  href={experiences.box_1_url}
                  className={BTPLStyles.HighlightButton}
                >
                  View Exclusive Collection
                </a>
              </span>
            </span>
          </div>
          <div className={BTPLStyles.PromoImage}>
            <img
              src={experiences.box_1_image}
              alt={experiences.box_1_title ?? ''}
            />
          </div>
        </div>
      )}
      {peakDistrict.length > 0 && (
        <Blocks
          title={'Peak District Special'}
          posts={peakDistrict}
          hasSpotlight={true}
          blockSlug={'peak-district-2024'}
          // settings={}
        />
      )}
      {southCoast.length > 0 && (
        <Blocks
          title={'South Special'}
          posts={southCoast}
          hasSlider
          // hasSpotlight
          // blockSlug={'weekend-escape-feature'}
          // settings={}
        />
      )}
      {eastAnglia.length > 0 && (
        <Blocks
          title={'East Anglia Special'}
          posts={eastAnglia}
          hasSlider
          // hasSpotlight
          // blockSlug={'weekend-escape-feature'}
          // settings={}
        />
      )}
      {southWest.length > 0 && (
        <Blocks
          title={'South West Special'}
          posts={southWest}
          hasSlider
          // hasSpotlight
          // blockSlug={'weekend-escape-feature'}
          // settings={}
        />
      )}
      {cotswolds.length > 0 && (
        <Blocks
          title={'Cotswolds Special'}
          posts={cotswolds}
          hasSlider
          // hasSpotlight
          // blockSlug={'weekend-escape-feature'}
          // settings={}
        />
      )}
      <Blocks title={`Popular Searches`} posts={POPULAR_SEARCHES} hasSlider />
      <MPUBanner adSlots={['Row1MPU1', 'Row1MPU2', 'Row1MPU3', 'Row1MPU4']} />
      <div className={`${BTPLStyles.InnerWrapper} ${BTPLStyles.Spacer}`}>
        <Heading>Collections</Heading>
        <div className={BTPLStyles.Counties}>
          <div className={BTPLStyles.County}>
            <Link to="/weekend-escapes/search/spas">
              <StaticImage
                src="../../../static/images/weekend-escapes/spas.jpg"
                alt={`Spas`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Spas</span>
            </Link>
          </div>
          <div className={`${BTPLStyles.County} ${BTPLStyles.DoubleWidth}`}>
            <Link to="/weekend-escapes/search/day-trip">
              <StaticImage
                src="../../../static/images/weekend-escapes/day-trip.jpg"
                alt={` Day Trip`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Day Trip</span>
            </Link>
          </div>
          <div className={BTPLStyles.County}>
            <Link to="/weekend-escapes/search/countryside">
              <StaticImage
                src="../../../static/images/weekend-escapes/countryside.jpg"
                alt={`Countryside`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Countryside</span>
            </Link>
          </div>
          <div className={BTPLStyles.County}>
            <Link to="/weekend-escapes/search/romantic">
              <StaticImage
                src="../../../static/images/weekend-escapes/romantic.jpg"
                alt={`Romantic`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Romantic</span>
            </Link>
          </div>
          <div className={BTPLStyles.County}>
            <Link to="/weekend-escapes/search/coastal">
              <StaticImage
                src="../../../static/images/weekend-escapes/coastal.jpg"
                alt={`Coastal`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Coastal</span>
            </Link>
          </div>
          <div className={`${BTPLStyles.County} ${BTPLStyles.DoubleWidth}`}>
            <Link to="/weekend-escapes/search/pet-friendly">
              <StaticImage
                src="../../../static/images/weekend-escapes/dog-friendly.jpg"
                alt={`Dog Friendly`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Dog Friendly</span>
            </Link>
          </div>
          <div className={BTPLStyles.County}>
            <Link to="/weekend-escapes/search/family">
              <StaticImage
                src="../../../static/images/weekend-escapes/family.jpg"
                alt={`Family`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Family</span>
            </Link>
          </div>
          <div className={BTPLStyles.County}>
            <Link to="/weekend-escapes/search/waterside">
              <StaticImage
                src="../../../static/images/weekend-escapes/waterside.jpg"
                alt={`Waterside`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Waterside</span>
            </Link>
          </div>
          <div className={BTPLStyles.County}>
            <Link to="/weekend-escapes/search/special-occasion">
              <StaticImage
                src="../../../static/images/weekend-escapes/special-occassion.jpg"
                alt={`Special Occasions`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Special Occasions</span>
            </Link>
          </div>
          <div className={BTPLStyles.County}>
            <Link to="/weekend-escapes/search/foodie">
              <StaticImage
                src="../../../static/images/weekend-escapes/foodie.jpg"
                alt={`Foodie`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Foodie</span>
            </Link>
          </div>
          <div className={BTPLStyles.County}>
            <Link to="/weekend-escapes/search/experiences">
              <StaticImage
                src="../../../static/images/weekend-escapes/experiences.jpg"
                alt={`Experiences`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>Experiences</span>
            </Link>
          </div>
          <div className={`${BTPLStyles.County} ${BTPLStyles.DoubleWidth}`}>
            <Link to="/weekend-escapes/search/city">
              <StaticImage
                src="../../../static/images/weekend-escapes/city.jpg"
                alt={`City`}
              />
              <div className={BTPLStyles.Shader}></div>
              <span className={BTPLStyles.Title}>City</span>
            </Link>
          </div>
        </div>
        <Hr />
      </div>
      <Blocks
        title={`By County`}
        posts={COUNTIES}
        hasSlider
        maxResults={COUNTIES.length}
      />
      <MPUBanner adSlots={['Row2MPU1', 'Row2MPU2', 'Row2MPU3', 'Row2MPU4']} />
      {recommended && (
        <Blocks title={`Recommended`} posts={recommended} hasSpotlight />
      )}
      {/* {southCoast.length > 0 && (
        <Blocks
          title={'South Coast Special'}
          posts={southCoast}
          hasSlider
          // hasSpotlight={true}
          // blockSlug={'south-weekend-escape-feature'}
          // settings={}
        />
      )}
      {eastAnglia.length > 0 && (
        <Blocks
          title={'East Anglia Special'}
          posts={eastAnglia}
          hasSlider
          // blockSlug={'weekend-escape-feature'}
          // settings={}
        />
      )}
      {weekendFeatures.length > 0 && (
        <Blocks
          title={'South West Special'}
          posts={weekendFeatures}
          hasSlider
          // blockSlug={'weekend-escape-feature'}
          // settings={}
        />
      )} */}
      <CTARow order={9}>
        <h3>Follow us!</h3>
        <p>See what we’re up to on your favourite social media networks</p>
        <Social />
      </CTARow>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default WeekendEscapes
